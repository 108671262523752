import { Route } from '@angular/router';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as fromSeriousSystem from '@serious-system';
import * as fromEnvironment from '../environments/environment';
import * as fromAuth from './auth';
import * as fromChats from './chats';
import * as fromChatsAssistants from './chats/assistants';
import * as fromConversation from './chats/conversation';
import * as fromLayout from './layout';
import { NotFoundPage } from './not-found.page';

export const appRoutes: Route[] = [
  {
    path: 'login',
    loadComponent: () => import('./auth/login.page').then((m) => m.LoginPage),
    canActivate: [fromAuth.loginGuard],
  },
  {
    path: 'playground',
    loadComponent: () =>
      import('./playground.page').then((m) => m.PlaygroundPage),
  },
  {
    path: 'chats',
    canActivate: [fromAuth.authGuard],
    canActivateChild: [fromAuth.authGuard],
    providers: [provideState(fromLayout.layoutFeature)],
    loadComponent: () =>
      import('./layout/layout.page').then((m) => m.LayoutPage),
    children: [
      {
        path: '',
        providers: [
          fromChats.ChatsSocket,
          provideState(fromChats.chatsFeature),
          provideEffects(fromChats.ChatsEffects),
          provideState(fromChatsAssistants.assistantsFeature),
          provideEffects(fromChatsAssistants.AssistantsEffects),
          fromSeriousSystem.provideVoiceToTextApiKeyService(
            fromEnvironment.environment.speechToTextProvider === 'azure'
              ? fromConversation.AzureApiKeyService
              : fromConversation.DeepgramApiKeyService
          ),
          fromSeriousSystem.provideVoiceToTextService(
            fromEnvironment.environment.speechToTextProvider === 'azure'
              ? fromConversation.AzureService
              : fromConversation.DeepgramService
          ),
        ],
        loadComponent: () =>
          import('./chats/chats.page').then((m) => m.ChatsPage),
        children: [
          {
            path: '',
            redirectTo: 'conversation',
            pathMatch: 'full',
          },
          {
            path: 'conversation',
            loadComponent: () =>
              import('./chats/conversation/conversation.page').then(
                (m) => m.ConversationPage
              ),
            pathMatch: 'full',
          },
          {
            path: 'conversation/:assistantUuid',
            loadComponent: () =>
              import('./chats/conversation/conversation.page').then(
                (m) => m.ConversationPage
              ),
            pathMatch: 'full',
            resolve: {
              assistantUuid: fromChatsAssistants.selectAssistantResolverFn,
            },
            canDeactivate: [
              fromChatsAssistants.unselectAssistantCanDeactivateFn,
            ],
          },
          {
            path: 'assistants',
            providers: [],
            loadComponent: () =>
              import('./chats/assistants/assistants.page').then(
                (m) => m.AssistantsPage
              ),
          },
        ],
      },
    ],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '**', component: NotFoundPage },
];
